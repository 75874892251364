<template>
  <div>
    <div v-html="text"></div>
    <v-rating
      empty-icon="mdi-star-outline"
      full-icon="mdi-star"
      color="tertiary"
      hover
      length="5"
      size="30"
      v-model="rating"
    ></v-rating>
    <br>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'StarRating',
  props: {
    questionKey: String,
  },
  data: () => ({
    rating: 0,
  }),
  computed: {
    text() {
      return this.$t(`rating.question${this.questionKey}`);
    },
  },
  methods: {
    ...mapActions('rating', [
      'createStarRating',
    ]),
  },
  watch: {
    rating(rating) {
      const { questionKey } = this;
      const data = { questionKey, rating };
      this.createStarRating(data);
    },
  },
};
</script>
